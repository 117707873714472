<template>
  <div class="bg-color" id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-show="loading">Loading...</dv-loading>
      <div class="host-body">
        <!--    临时logo    -->
        <!--        <el-image-->
        <!--            v-if="showLogo"-->
        <!--            draggable="false"-->
        <!--            v-show="!loading"-->
        <!--            class="sg_logoOut"-->
        <!--            :src="require('../assets/img/la_logo.png')"-->
        <!--        />-->
        <!--地图-->
        <div id="container" ref="mapRef"/>
        <!--  标题  -->
        <div v-show="!loading" id="title-background" class="d-flex jc-between">
          <div style="margin: auto">
            <div style="display: block; height: 100px; align-content: center">
              <div
                  style="font-size: xxx-large; line-height: 50px;font-weight:bold;text-align: center"
              >
                华燃新能源数字化厨房
              </div>
            </div>
          </div>
          <div class="d-flex select-left" style="top: 70px">
            <div>{{ largeScreenTitle }}</div>
          </div>
          <!--     全屏按钮及其他功能按钮     -->
          <div class="d-flex select-right" style="top: 70px">
            <div>{{ dateYear }} {{ dateDay }} {{ dateWeek }}</div>
            <i
                class="el-icon-full-screen"
                @click="UIOnShow = !UIOnShow"
                style="margin-left: 5px; color: #3f8cff; z-index: 22"
            />
          </div>
          <div id="title_line"></div>
        </div>
        <div class="mapLoadingText_out" v-if="!finishMapData && !loading">
          地图加载中...
        </div>
        <div v-show="!loading" class="mapButtonOut">
          <div
              style="
              display: flex;
              flex-direction: column;
              padding: 10px 0 0 24px;
            "
          >
            <button class="mapZoomButton" @click="mapZoom('in')">+</button>
            <button class="mapZoomButton" @click="mapZoom('out')">-</button>
          </div>
        </div>
      </div>
      <!--   商家详细信息弹窗   -->
      <bus-detail
          v-if="busInfoSwitch"
          class="winBg"
          ref="busDetailWin"
          @onClose="onCloseBusWin"
      />
    </div>
  </div>
</template>

<script>
import {formatTime} from "@/utils";
import mqtt from "mqtt";
import drawMixin from "../utils/drawMixin";
import {getBusDataByGWid, getBusDetail, getSSE, getTokenByCode} from "@/api/common";
import AMapLoader from "@amap/amap-jsapi-loader";
import busDetail from "./components/busDetail.vue";


function getCode(variable) {
  const query = window.location.href;
  const vars = query.split("?");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

//全局地图
let mainMap = null;
//海量点图层
let mapMass = null;

export default {
  components: {
    busDetail,
  },
  mixins: [drawMixin],
  data() {
    return {
      // 是否显示logo
      // showLogo: true,
      // 显示标题
      largeScreenTitle: "",
      //计时器
      loadingTimeout: null,
      loopTimeOut: null,
      //地图数据
      UIOnShow: true,
      loading: false,
      //右上角年月日
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      timing: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      //弹窗数据
      busInfoSwitch: false, // 商户详细信息弹窗
      //地图推流
      finishMapData: false, //是否加载完毕
      //mqtt
      connection: {
        host: "ws.hwarise.com",
        port: 8083,
        endpoint: "/mqtt",
        clean: true, // 保留会话
        connectTimeout: 4000, // 超时时间
        keepalive: 300, //存活时间
        reconnectPeriod: 30000, // 重连时间间隔
        // 认证信息
        clientId: "mqttjs_3be2c321",
        username: "coolpy7_test",
        password: "coolpy7_test",
      },
      subscription: {
        topic: "hr/upload/#",
        qos: 1,
      },
      receiveNews: "",
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      warningList: [],
      busDetailWinGWid: '',
      gwidList: [],
    };
  },
  async created() {
    this.loading = true;
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    if (sessionStorage.getItem("Info") === null) {
      let code = getCode("code");
      let res = await getTokenByCode(code);
      if (res.code === 0) {
        sessionStorage.setItem("Info", JSON.stringify(res.result));
      } else {
        console.log("获取用户基本信息失败！");
      }
    }

    this.userInfo = JSON.parse(sessionStorage.getItem("Info"));
    if (this.userInfo?.addr_path) {
      this.largeScreenTitle = JSON.parse(
          JSON.stringify(this.userInfo?.addr_path).replace(/\//g, "")
      );
    }

    await this.mapInit();
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = null;
    }
    this.loadingTimeout = setTimeout(() => {
      this.loading = false;
    }, 1400);
    this.timeFn();
    // this.onKeyDown();
    this.$nextTick(() => {
      this.createConnection();
    });
    this.loopTimeOut = setTimeout(this.loopGetData, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timing);
    clearTimeout(this.loadingTimeout);
    clearTimeout(this.loopTimeOut);
    sessionStorage.clear();
    mainMap && mainMap.destroy();
    this.doUnSubscribe();
    this.destroyConnection();
  },
  computed: {
    styleIndex() {
      return (isLow, time) => {
        if (isLow) return 2;
        if (!time) return 0;
        if (Date.now() / 1000 - time >= 900) return 0;
        return 1;
      }
    }
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH:mm:ss");
        this.dateYear = formatTime(new Date(), "yyyy年MM月dd日");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    //地图初始化
    mapInit() {
      let that = this;
      AMapLoader.load({
        key: "0400f3b955c4941f868fe510bb1addf6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.PlaceSearch", "AMap.MassMarks", "AMap.Marker"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        Loca: {
          version: "2.0.0",
        },
      })
          .then(async (AMap) => {
            mainMap = new AMap.Map("container", {
              //设置地图容器id
              viewMode: "3D", //是否为3D地图模式
              zoom: 16, //初始化地图级别
              pitch: 40,
              mapStyle: "amap://styles/dark", //标准样式
              features: ["bg", "road"],
            });
            //根据path定位地图中心点
            if (that.userInfo.addr_path) {
              let cityName = JSON.parse(JSON.stringify(that.userInfo.addr_path))
                  .split("/")
                  .pop();
              mainMap.setCity(cityName);
            }


            // 实例化massMark
            mapMass = new AMap.MassMarks([], {
              opacity: 0.8,
              zIndex: 899,
              cursor: "pointer",
              style: [
                {
                  url: require("../assets/icon/yellow.png"),
                  anchor: new AMap.Pixel(8, 8),
                  size: new AMap.Size(16, 16),
                  zIndex: 800,
                },
                {
                  url: require("../assets/icon/green.png"),
                  anchor: new AMap.Pixel(10, 10),
                  size: new AMap.Size(20, 20),
                  zIndex: 800,
                },
                {
                  url: require("../assets/icon/red.png"),
                  anchor: new AMap.Pixel(15, 15),
                  size: new AMap.Size(30, 30),
                  zIndex: 900,
                },
              ],
            });
            mapMass.setMap(mainMap);


            //获取地图点
            that.getMapData(that.userInfo.addr_path);

            //实例化文本事件
            const text = new AMap.Text({
              text: "纯文本标记",
              anchor: "left", // 设置文本标记锚点
              draggable: true,
              cursor: "pointer",
              angle: 0,
              visible: false,
              offset: [10, -40],
              style: {
                padding: "5px 10px",
                "margin-bottom": "1rem",
                "border-radius": ".25rem",
                "background-color": "rgba(0,0,0,0.5)",
                // 'width': '12rem',
                "border-width": 0,
                "box-shadow": "0 2px 6px 0 rgba(255, 255, 255, .3)",
                "text-align": "center",
                "font-size": "16px",
                color: "#fff",
              },
            });
            text.setMap(mainMap);


            //海量点鼠标移入事件
            mapMass.on("mousemove", (e) => {
              let shopName = e.data.name;
              if (e.data.style === 0) shopName += '(离线)'
              text.setText(shopName);
              text.setPosition(e.data.lnglat);
              text.show();
            });
            mapMass.on("mouseout", () => {
              text.hide();
            });
            //点击事件
            mapMass.on("click", function (e) {
              that.onShowBusWin(e.data.busid, e.data.gwid, e.data.name);
            });

            //地图尺寸变更事件，缩放结束时获取地图缩放等级更新点尺寸
            mainMap.on("zoomend", function () {
              const level = Math.round(mainMap.getZoom());
              if (level > 16) {
                mainMap.setFeatures(["bg", "point", "road", "building"]);
              } else {
                mainMap.setFeatures(["bg", "road"]);
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });
    },
    //循环定时器
    loopGetData() {
      if (this.loopTimeOut) {
        clearTimeout(this.loopTimeOut);
        this.loopTimeOut = null;
      }
      //重载地图点；
      console.log('重载');
      this.getMapData();
      this.loopTimeOut = setTimeout(this.loopGetData, 5000);
    },
    //获取地图点
    async getMapData() {
      this.finishMapData = false;
      let massMapData = [];
      try {
        let res = await getSSE(this.userInfo.addr_path);
        if (res.code !== 0) return console.log("获取地图点有误！");
        if (!res.result || res.result.length === 0)
          return console.log("无返回地图点信息");
        this.gwidList = res.result.gw_ids;
        if (mapMass) mapMass.clear();
        res.result.list.forEach((item) => {
          let temp = {
            gwid: item.gateway_id,
            name: item.name,
            lnglat: item.location.coordinates,
            busid: item._id,
            style: this.styleIndex(item.is_low, item.last_online_at),
          }
          massMapData.push(temp);
        });
        if (mapMass.length !== 0) {
          mapMass.setData(massMapData);
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.finishMapData = true;
      }
    },
    //商事主体弹窗显示及内容显示
    onShowBusWin(busid, gwid) {
      this.busDetailWinGWid = gwid;
      this.busInfoSwitch = true;
      this.$nextTick(() => {
        if (this.$refs.busDetailWin) {
          this.$refs.busDetailWin.init(busid, gwid);
        }
      });
    },
    //关闭商事主体弹窗
    onCloseBusWin() {
      this.busInfoSwitch = false;
      this.busDetailWinGWid = '';
    },
    //地图缩放
    mapZoom(type) {
      let zoom = mainMap.getZoom();
      if (type === "in") {
        if (zoom < 20) {
          zoom++;
          mainMap.setZoom(zoom);
        }
      } else {
        if (zoom > 3) {
          zoom--;
          mainMap.setZoom(zoom);
        }
      }
    },
    // 创建连接
    createConnection() {
      // 连接字符串, 通过协议指定使用的连接方式
      // ws 未加密 WebSocket 连接
      // wss 加密 WebSocket 连接
      // mqtt 未加密 TCP 连接
      // mqtts 加密 TCP 连接
      // wxs 微信小程序连接
      // alis 支付宝小程序连接
      if (this.userInfo.token) this.connection.password = this.userInfo.token;
      const {host, port, endpoint, ...options} = this.connection;
      const connectUrl = `wss://${host}:${port}${endpoint}`;
      try {
        this.client = mqtt.connect(connectUrl, options);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
      this.client.on("connect", () => {
        console.log("Connection succeeded!");
      });
      this.client.on("error", (error) => {
        console.log("Connection failed", error);
      });
      this.client.on("message", async (topic, message) => {
        this.receiveNews = this.receiveNews.concat(message);
        let res = JSON.parse(`${message}`) //转换mqtt数据

        if (this.gwidList.length === 0) return //当前单位没有设备，不提醒
        if (!(this.gwidList.includes(res.gwid))) return  //在gwid列表里面没找到的，不提醒

        let busRes = await getBusDataByGWid(res.gwid);//根据gwid获取商户信息，用于判断
        if (busRes.code !== 0) return; //获取商户信息失败就停止
        if (res.gwid === this.busDetailWinGWid) { //在弹窗打开时，gwid相同推入更新弹窗
          this.$refs.busDetailWin.upDate(res, busRes.result);
        }
        if (res.ex / 100 > busRes.result.low_fuel_warning) {//根据当前液位，与商户的最低液位比较，大于就踢出警告列表，并且不需要提示
          this.warningList = this.warningList.filter(item => item !== res.gwid)
          return;
        }
        if (this.warningList.find(item => item === res.gwid)) return;//已经在警告列表的不再提示
        let busDetailRes = await getBusDetail(busRes.result._id) //重新获取一下警告弹窗的商户详情
        if (busDetailRes.code !== 0) return;
        this.$notify({
          title: '设备液位过低',
          dangerouslyUseHTMLString: true,
          message: `<div><p>${busDetailRes.result.name}(${busDetailRes.result.principal}:${busDetailRes.result.principal_mobile})</p><p>地址:${busDetailRes.result.address}</p></div>`,
          type: 'error',
          onClick: () => {
            this.onShowBusWin(busDetailRes.result._id, busDetailRes.result.gateway_id)
          },
        })
        this.warningList.push(res.gwid);

      });
      this.doSubscribe();
    },

    // 订阅主题
    doSubscribe() {
      const {topic, qos} = this.subscription;
      this.client.subscribe(topic, {qos}, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        // console.log("Subscribe to topics res", res);
      });
    },
    // 取消订阅
    doUnSubscribe() {
      const {topic} = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        } else {
          this.subscribeSuccess = false;
        }
      });
    },
    // 断开连接
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end();
          this.client = {
            connected: false,
          };
          console.log("Successfully disconnected!");
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    notifyList(arr) {
      if (arr.length === 0) return;
      let j = 0;
      for (let i = 0; i < arr.length; i++) {
        if (this.warningList.find(item => item === arr[i].properties.gwid)) break;
        setTimeout((() => {
          this.$notify({
            title: '设备液位过低',
            dangerouslyUseHTMLString: true,
            message: `<div><p>${arr[i].properties.name}(${arr[i].properties.gwid})</p><p>设备地址:${arr[i].properties.address}</p></div>`,
            type: 'error',
            onClick: () => {
              this.onShowBusWin(arr[i].properties.busid, arr[i].properties.gwid)
            },
          })
        }), 1000 * j)
        j++;
        this.warningList.push(arr[i].properties.gwid);
      }

    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index2.scss";
</style>
<style>
.bg-color {
  background-color: #020308;
}

.bg {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
  z-index: 200;
}

.select-left {
  position: absolute;
  top: 20px;
  left: 0;
}

.select-right {
  position: absolute;
  top: 35px;
  right: 0;
}


.select-width input {
  height: 16px !important;
}


#container {
  transform-origin: left top;
}


.mapButtonOut {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 16px;
  bottom: 36px;
}

.mapZoomButton {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 1px;
}

.winBg {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 990;
}


.sg_logoOut {
  position: absolute;
  bottom: 10px;
  width: 190px;
  height: 70px;
  opacity: 0.4;
  z-index: 20;
}

.mapLoadingText_out {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

button {
  cursor: pointer;
}

#title-background {
  position: absolute;
  text-align: center;
  top: 14px;
  width: 99%;
  height: 100px;
  background-image: linear-gradient(
      to right,
      rgba(46, 135, 227, 0),
      rgba(11, 125, 234, 0.5),
      rgba(46, 135, 227, 0)
  );
}

#title_line {
  position: absolute;
  bottom: 1px;
  width: 100%;
  height: 2px;
  box-shadow: 0 0 20px floralwhite;
  background: linear-gradient(
      to right,
      rgb(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.8),
      rgb(255, 255, 255, 0.1)
  );
}
</style>
